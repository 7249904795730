import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allWordpressWpStations {
        edges {
          node {
            title
            acf {
              price_list_link
              website_link
            }
          }
        }
      }
    }
  `)
  return data
}
