/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import propTypes from "prop-types"
import stationLinksData from "../queries/staticQueries/stationLinksData"

import "./layout.css"
import "../styles/main.scss"

function stationLinks() {
  const data = stationLinksData()
  return (
    <React.Fragment>
      <ul>
        {data.allWordpressWpStations.edges.map(({ node }) => (
          <li className="station">
            <a href={node.acf.website_link}>
              <h3 className="name">{node.title}</h3>
            </a>
            <a className="price-list" href={node.acf.price_list_link}>
              <span>Verðskrá</span>
            </a>
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}

stationLinks.propTypes = {
  edges: propTypes.array,
}

export default stationLinks
