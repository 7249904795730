import React, { Component } from "react"
import GoogleMapReact from "google-map-react"
// import WOW from "wow.js/dist/wow.js"

const Marker = ({ text }) => <div className="marker"></div>

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 64.13032,
      lng: -21.87617,
    },
    zoom: 15,
  }

  // componentDidMount() {
  //   const wow = new WOW()
  //   wow.init()
  // }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div
        style={{ height: "315px", width: "100%" }}
        className="wow fadeIn"
        data-wow-delay="0.3s"
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDKfBB0EG95giJAPOnyygQ2ldKmlMqaBZk" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={64.13032} lng={-21.87617} text="Hér erum við!" />
        </GoogleMapReact>
      </div>
    )
  }
}

export default SimpleMap
