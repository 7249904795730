
import { graphql, useStaticQuery} from "gatsby"



export default  () => {
    const data = useStaticQuery(graphql`
    query newsDataQuery{
        allWordpressWpNewsItems {
            edges {
                node {
                    wordpress_id
                    title
                    slug
                    content
                    id
                    date
                    acf{
                        language
                        visibility
                    }
                }
            }
        } 
    }
  `)
    return data
  }