import React, { Component } from "react"
// import WOW from "wow.js/dist/wow.js"

class ContactForm extends Component {
  // componentDidMount() {
  //   const wow = new WOW()
  //   wow.init()
  // }
  render() {
    const language = this.props.language
    return (
      <React.Fragment>
        <h2 className="title wow fadeIn" data-wow-delay="0.3s">
          {language === "en" ? "Contact us" : "Hafðu samband"}
        </h2>
        <form
          action="https://formspree.io/mdooqzvq"
          method="POST"
          className="row wow fadeIn"
          data-wow-delay="0.3s"
        >
          <label className="col-md-6">
            <input
              type="text"
              placeholder={language === "en" ? "Your name" : "Nafn þitt"}
              name="Name"
              className="form-control"
              required
            />
          </label>
          <label className="col-md-6">
            <input
              type="text"
              placeholder={language === "en" ? "Email" : "Netfang"}
              name="Email"
              className="form-control"
              required
            />
          </label>
          <label className="col-md-12">
            <input
              type="text"
              placeholder={language === "en" ? "Subject" : "Efni"}
              name="Subject"
              className="form-control"
              required
            />
          </label>
          <label className="col-md-12">
            <textarea
              name="message"
              className="form-control"
              placeholder={
                language === "en"
                  ? "How can we help?"
                  : "Hvernig getum við hjálpað?"
              }
              required
            ></textarea>
          </label>
          <div className="col-md-12">
            <button type="submit" className="submit-btn">
              {language === "en" ? "Send" : "Senda"}
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default ContactForm
